<template>
    <div>
        <p :class ="textColor + ' text-h5 font-weight-bold text--darken-1 pt-10 pb-2 mb-0'">{{ title }}</p>
        <p :class ="textColor + ' text-h6 font-weight-bold text--darken-1 pt-2'">
            外皮性能（{{ title }}）
        </p>
        <v-simple-table :style="'border:1px solid ' + tableBorderColor">
            <template v-slot:default >
                <tbody>
                    <tr>
                        <td :class ="textColor + ' text--darken-1'" style="border-left:1px solid #ccc; width:16.6666%;">外皮基準適合戸数</td>
                        <td colspan="2" class="black--text" style="border-left:1px solid #ccc;">{{ envelopePerformance.adaptableHouseholdTotalNumDu == '-' ? '-' : envelopePerformance.adaptableHouseholdTotalNumDu + '戸' }}</td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text--darken-1'" style="border-left:1px solid #ccc;">基準値</td>
                        <td class="black--text" style="border-left:1px solid #ccc; width: 33.3333%;">U<sub>A</sub> = {{ envelopePerformance.householdStandardUaDu }}</td>
                        <td class="black--text" style="border-left:1px solid #ccc; width: 50%;">&#951;<sub>AC</sub> = {{ envelopePerformance.householdStandardEtaAcDu }}</td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text--darken-1'" style="border-left:1px solid #ccc;">設計値（最大値）</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">U<sub>A</sub> = {{ envelopePerformance.householdMaxUa }}</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">&#951;<sub>AC</sub> = {{ envelopePerformance.householdMaxEtaAc }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <p :class ="textColor + ' text-h6 font-weight-bold text--darken-1 pt-10'">
            住宅部分の一次エネルギー消費量（{{ title }}）
        </p>
        <v-simple-table :style="'border:1px solid ' + tableBorderColor">
            <template v-slot:default >
                <tbody class="text-center">
                    <tr>
                        <td style="width:16.6666%;"></td>
                        <td style="border-left:1px solid #ccc; width:16.6666%;">
                            <span :class ="textColor + ' text--darken-1 d-block'">
                                設計一次<br/>エネルギー消費量
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc; width:16.6666%;">
                            <span :class ="textColor + ' text--darken-1 d-block'">
                                基準一次<br/>エネルギー消費量
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc; width:16.6666%;">
                            <span :class ="textColor + ' text--darken-1 d-block'">
                                設計一次<br/>エネルギー消費量<br/>(その他除く)
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc; width:16.6666%;">
                            <span :class ="textColor + ' text--darken-1 d-block'">
                                基準一次<br/>エネルギー消費量<br/>(その他除く)
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td :class ="textColor + ' text--darken-1'" style="border-left:1px solid #ccc; width:16.6666%;">BEI</td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text--darken-1 text-left'">住戸部分</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.eT }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.eSt }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.eTWithoutEM }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.eStWithoutESm }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.bei }}</td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text--darken-1 text-left'">共用部分</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.eT : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.eSt : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.eTWithoutEM : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.eStWithoutESm : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.bei : "0.0" }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                        <td :class ="textColor + ' text-right text--darken-1'">合計</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.eT }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.eSt }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.eTWithoutEM }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.eStWithoutESm }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.bei }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-row :class ="textColor + ' text-h6 font-weight-bold text--darken-1 pt-10 pb-5 pl-3 pr-3'">
            単位住戸の情報（{{ title }}）
            <v-spacer></v-spacer>
            {{ buildingInfo.householdTotalNum }}件
        </v-row>
        <v-data-table
            :style="'border:1px solid ' + tableBorderColor"
            class="tbl"
            :items="householdResultsTable.householdResultsTable"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions : [50,100],
                itemsPerPageText : ''
            }"
        >
            <template v-slot:body="props">
                <tbody class="text-center">
                    <tr :class ="textColor + ' text--darken-1'">
                        <td rowspan="2" style="width:8.3333%;">住戸の番号</td>
                        <td rowspan="2" style="width:8.3333%;">住宅タイプの名称</td>
                        <td rowspan="2" style="width:8.3333%;">住戸の存する階</td>
                        <td rowspan="2" style="width:8.3333%;">
                            床面積の合計
                            <span class="black--text d-block">[m<sup>2</sup>]</span>
                        </td>
                        <td colspan="3" style="border-left:1px solid #ccc; width:25%;">外皮性能</td>
                        <td colspan="5" style="border-left:1px solid #ccc; width:41.6666%;">一次エネルギー消費性能</td>
                    </tr>
                    <tr :class ="textColor + ' text--darken-1'">
                        <td style="border-left:1px solid #ccc;">
                            外皮平均<br/>熱貫流率<br/>(U<sub>A</sub>)
                            <span class="black--text d-block">[W/m<sup>2</sup>K]</span>
                        </td>
                            <td>
                            冷房期平均<br/>日射熱取得率<br/>(&#951;<sub>AC</sub>)
                            <span class="black--text d-block">[-]</span>
                        </td>
                        <td>判定</td>
                        <td style="border-left:1px solid #ccc;">
                            設計一次<br/>エネルギー<br/>消費量
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            設計一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            BEI
                        </td>
                    </tr>

                    <tr v-for="(item , index) in props.items" :key="index">
                        <td :class ="textColor + ' text--darken-1 text-left font-weight-bold'">
                            {{ item.householdNum }}
                        </td>
                        <td>{{ item.householdType }}</td>
                        <td>{{ item.floor }}</td>
                        <td>{{ item.totalArea }}</td>
                        <td style="border-left:1px solid #ccc;">{{ item.ua }}</td>
                        <td>{{ item.etaAc }}</td>
                        <td>{{ item.statusP }}</td>
                        <td style="border-left:1px solid #ccc;">{{ item.eTDu }}</td>
                        <td>{{ item.eStDu }}</td>
                        <td>{{ item.eTDuWithoutEM }}</td>
                        <td>{{ item.eStDuWithoutESm }}</td>
                        <td>{{ item.beiDu }}</td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <!-- BEI情報 -->
        <bei-info v-bind:beiInfo="beiInfo" :title="title" :textColor="textColor" :tableBorderColor="tableBorderColor"></bei-info>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BeiInfo from './BeiInfo.vue'


export default({
    props: ['aggregateResult', 'title', 'standardType'],
    components: {
        BeiInfo
    },
    computed: {
        ...mapGetters('basicInfo', {
            basicInfo: 'getBasicInfo',
            commonAreaType: 'getCommonAreaType'
        }),
        // 物件概要の取得
        buildingInfo() {
            return this.aggregateResult.buildingInfo
        },
        // 単位住戸の情報
        householdResultsTable() {
            return this.aggregateResult.householdResultsTable
        },
        // 外皮性能集計表
        envelopePerformance() {
            return this.aggregateResult.envelopePerformance
        },
        // 共用部分の一次エネルギー消費量の合計
        householdEnergyConsumption(){
            return this.aggregateResult.energyConsumptions.householdEnergyConsumption
        },
        // 住戸部分の一次エネルギー消費量の合計
        commonEnergyConsumption(){
            return this.aggregateResult.energyConsumptions.commonareaEnergyConsumption
        },
        // 一次エネルギー消費量の合計
        totalEnergyConsumption(){
            return this.aggregateResult.energyConsumptions.totalEnergyConsumption
        },
        // BEI情報
        beiInfo(){
            return this.aggregateResult.beiInfo
        },
        // Text Color
        textColor(){
            return this.standardType == "general" ? "teal--text" : "success--text"
        },
        // Table Border Color
        tableBorderColor(){
            return this.standardType == "general" ? "#4ACAA0" : "#5f3e31"
        }
    }
})
</script>
